import React from 'react'
import {
   PageHeader, Row, Col, Card, Skeleton, Button
} from 'antd'
import { Link } from 'react-router-dom'
import { menuTypeHook } from '../../components/common/menuTypeHook'

class StyledBookIndex extends React.Component {
  render () {
    const { books, stats, isMobile, loaded } = this.props

    return (
      <>
        <PageHeader
          title='Moja biblioteczka'
          extra={[
            <Button
              key='add-recipe'
              type='primary'
              onClick={() => this.props.setAddBookModalVisible(true)}
            >
              Dodaj
            </Button>
          ]}
        />
        <h2>
          {stats && Object.keys(stats).map(year => <p>{year}: stron: {stats[year].pages}, książek: {stats[year].count}</p>)}
        </h2>
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <div style={{ marginLeft: '20px', marginRight: '20px', display: loaded ? 'block' : 'none' }} className='list'>
          <Row gutter={[0, 10]}>
            {books?.map(item => {
              return <Col xs={12} md={8} lg={6} key={item.id}>
                <Card
                  bordered={false}
                  className={'book-item' + (isMobile ? ' mobile' : '')}
                  style={{
                    borderBottom: '10px solid brown'
                  }}
                >
                  <Link to={'/book/' + item.id}><img className='book-item-image' width={196} src={'/dimages/book_images/min-' + item.id + '.jpg?' + new Date().getTime()} alt={item.title} /></Link>
                </Card>
              </Col>
            })}
          </Row>
        </div>
      </>
    )
  }
}

export default menuTypeHook(StyledBookIndex)
