import React from 'react'
import StyledBookIndex from '../../styledComponents/book'
import { getBooks } from '../../utils/actions'
import AddBookModal from './addModal'
import { connect } from 'react-redux'

class BookIndex extends React.Component {
  state = {
    loaded: false,
    addBookModalVisible: false
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  componentDidMount () {
    this._isMounted = true
    this.getBooks()
  }

  setAddBookModalVisible = (addBookModalVisible) => {
    this.setState({ addBookModalVisible })
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getBooks = () => {
    getBooks().then((json) => {
      // console.log('json', json)
      if (this._isMounted) {
        this.setState({ books: json?.books, stats: json?.stats, loaded: true })
      }
    })
  }

  render () {
    const { isMobile } = this.props
    const { books, stats, loaded } = this.state

    return (
      <>
        <AddBookModal
          visible={this.state.addBookModalVisible}
          setModalVisibility={this.setAddBookModalVisible}
          getBooks={this.getBooks}
          isMobile={isMobile}
          redirect={this.redirect}
        />
        <StyledBookIndex
          setAddBookModalVisible={this.setAddBookModalVisible}
          isMobile={isMobile}
          books={books}
          stats={stats}
          redirect={this.redirect}
          from_route={this.props.location.state?.from}
          loaded={loaded}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
}

const BookIndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookIndex)

export default BookIndexContainer
