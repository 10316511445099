/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  PageHeader, Button, Popconfirm
} from 'antd'
import BookForm from '../../styledComponents/book/form'
import { getBook, updateBook, deleteBook } from '../../utils/actions'
import { connect } from 'react-redux'
import { openNotification } from '../../utils/helpers'

class BookShow extends React.Component {
  state = {
    id: undefined,
    book: undefined,
    downloading: false,
    title: 'Książka'
  }

  getBook = (id) => {
    getBook(id).then((json) => {
      if (this._isMounted) {
        this.setState({ book: json, title: json.title })
      }
    })
  }

  deleteBook = () => {
    deleteBook(this.state.id).then(() => {
      openNotification('Sukces', 'Pomyślnie usunięto!')
      this.redirect('/book')
    })
  }

  componentDidMount () {
    const { id } = this.props.match.params
    this._isMounted = true

    this.setState({ id })
    this.getBook(id)
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  setTitle = (title) => {
    this.setState({ title })
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  updateBook = (data) => {
    const _this = this
    return new Promise(function (resolve, reject) {
      updateBook(_this.state.id, data).then((json) => {
        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  render () {
    const { isMobile } = this.props
    const { book } = this.state

    const popconfirmProps = {
      okText: 'Tak',
      cancelText: 'Anuluj',
      placement: 'bottom',
      cancelButtonProps: isMobile ? { size: 'large' } : undefined,
      okButtonProps: isMobile ? { size: 'large' } : undefined
    }

    return (
      <>
        <PageHeader
          title={this.state.title}
          onBack={() => this.redirect('/book')}
          extra={[<Popconfirm
            title='Czy na pewno usunąć książkę?'
            onConfirm={this.deleteBook}
            {...popconfirmProps}
          >
            <Button>Usuń</Button>
          </Popconfirm>]}
        />
        <BookForm
          formAction={this.updateBook}
          formData={book}
          setTitle={this.setTitle}
          isMobile={isMobile}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
}

const BookShowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookShow)

export default BookShowContainer
